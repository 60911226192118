import styled from 'styled-components'
import { Container } from 'reactstrap'

import media from 'styles'

import Background from 'images/contact_particles.png'

export default styled(Container).attrs({ fluid: true })`
  margin-top: 100px;
  padding: 30px 0 65px 0;
  background: url(${ Background }) no-repeat left top;

  ${ media.lessThan('lg')`
    margin-top: 50px;
  ` }
`
