import React from 'react'
import Helmet from 'react-helmet'
import { Container } from 'reactstrap'

import Layout from 'layouts/static'

import Main from 'components/Main'

import Contact from 'components/Contact'

import Form from 'views/contact/Form'

const ContactPage = () => {
  return (
    <Layout variant="secondary">
      <Helmet title="Kontakt">
        <meta
          name="description"
          content="Poznajmy się bliżej. Skontaktuj się z nami i wspólnie zrealizujmy Twój pomysł."
        />
      </Helmet>

      <Main>
        <Form>
          <Container>
            <Contact title="Kontakt z nami" subtitle="Współpraca" />
          </Container>
        </Form>
      </Main>
    </Layout>
  )
}

export default ContactPage
